<template>
  <main class="main" role="main">
    <div class="page">
      <div class="inner">
        <div class="page_con">
          <div class="con_ttl center">
            <span>위즈캐시 충전</span>
          </div>
          <div class="form_wrap">
            <div class="form">
              <ul class="form_ul">
                <li class="form_li">
                  <div class="form_ttl">결제 방법 선택</div>
                  <div class="form_in">
                    <select
                      :value="$ConstCode.PAY_TYPE[1].value"
                      class="disabled"
                      disabled
                    >
                      <option :value="$ConstCode.PAY_TYPE[1].value">{{
                        $ConstCode.PAY_TYPE[1].name
                      }}</option>
                    </select>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_ttl essen">충전 캐시</div>
                  <div class="form_in">
                    <label class="input">
                      <input
                        type="number"
                        inputmode="decimal"
                        pattern="\d*"
                        disabled
                        :min="0"
                        @keyup="maskingAmountNumber"
                        :value="amount"
                      />
                    </label>
                  </div>
                </li>
                <li class="form_li">
                  <div class="form_in">
                    <div class="button_line">
                      <button @click="controlAmountWithNumber(1000)">
                        + 1천원
                      </button>
                      <button @click="controlAmountWithNumber(10000)">
                        + 1만원
                      </button>
                      <button @click="controlAmountWithNumber(-1000)">
                        - 1천원
                      </button>
                      <button @click="controlAmountWithNumber(-10000)">
                        - 1만원
                      </button>
                    </div>
                  </div>
                  <div class="hint">결제 수수료를 포함한 총 결제금액</div>
                  <div class="form_in">
                    <span class="font_weight">{{
                      $Util.formatNumber2(total, { unit: '' })
                    }}</span>
                    원
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div class="con_btn">
            <div class="btn_wrap">
              <button class="btn bo" @click="fnGoBack">취소</button>
              <button class="btn" @click="fnModalAlertChargeRequestView">
                충전하기
              </button>
            </div>
          </div>
          <div class="caution">
            <div class="inner">
              <dl>
                <dt>충전안내</dt>
                <dd>
                  <div>
                    * 결제수수료: 충전금액의 3.3%(부가세 포함)
                  </div>
                  <div>
                    * 최소 충전금액: 1,000원
                  </div>
                  <div>
                    * 한도: 월 최대 300,000원
                  </div>
                  <div class="font_weight">
                    * 충전금액 단위: 1,000원 단위
                  </div>
                  <div>
                    * 충전 취소: 충전 후 7일 이내 사용이력이 없는 경우 위즈니
                    고객센터를 통해 충전 취소가 가능합니다.
                  </div>
                </dd>
              </dl>
            </div>
          </div>
        </div>
      </div>
    </div>
    <settle v-bind:="settleForm" :callback="fnCallback" />
    <nice
      :id="'formNice16'"
      :m="niceForm.m"
      :encode-data="niceForm.encodeData"
      :call-type="niceForm.callType"
      :rtn-url="rtnUrl"
      :fail-rtn-url="failRtnUrl"
      :callback="fnCallbackNice"
    />
  </main>
</template>

<script>
import { componentState } from './index.js'
import Settle from '@/components/common/settle/index.vue'

export default {
  name: 'my-page-cash-charge-index',
  components: { Settle },
  setup() {
    return { ...componentState() }
  }
}
</script>

<style scoped></style>
