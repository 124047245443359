import { computed, reactive, toRefs, getCurrentInstance } from 'vue'
import { useRouter, useRoute } from 'vue-router'
import { useStore } from 'vuex'
import { niceForm } from '@/common/form/NiceForm'
import { settleForm } from '@/common/form/SettleForm'
import { CommonFunction } from '@/common/CommonFunction.js'
import { fnModalAlert } from '@/components/common/modal/alert/index.js'

export const componentState = () => {
  const { proxy } = getCurrentInstance()
  const route = useRoute()
  const router = useRouter()
  const { getters, dispatch } = useStore()
  const state = reactive({
    userData: computed(() => getters['user/getData']),
    previousRoutes: computed(() => getters['previousRoute']),
    settleForm: proxy.$_.merge({}, settleForm),
    fee: 0.033,
    computedFee: computed(() => Math.round(state.amount * state.fee)),
    niceForm: { ...niceForm },
    rtnUrl: `${window.location.origin}/my-info/success`,
    failRtnUrl: `${window.location.origin}/my-info/fail`,
    cash: 0,
    monthCharge: 0,
    limitCharge: computed(() => 300000 - state.monthCharge),
    amount: '',
    total: computed(() => Number(state.amount) + state.computedFee)
  })
  const { fnMyInfo, fnUpdateAuthType } = CommonFunction(state)
  const isValidRequest = () => {

    alert('충전을 서비스가 종료되었습니다. 자세한 내용은 공지사항을 확인 부탁드립니다.')
    return false

    // if (proxy.$Util.isEmpty(state.amount)) {
    //   alert('충전할 금액을 확인해주세요.')
    //   return false
    // }
    // if (Number(state.amount) < 1000 || Number(state.amount) > 300000) {
    //   alert('1,000원 이상부터 충전, 월 최대 300,000캐시 충전이 가능합니다.')
    //   return false
    // }
    // if (state.amount.length > 3) {
    //   if (
    //     state.amount.slice(-3) !== '000' &&
    //     state.amount.slice(-3).includes('0')
    //   ) {
    //     alert('1000원 단위로 충전 가능합니다.')
    //     return false
    //   }
    // }
    // if (Number(state.amount) > state.limitCharge) {
    //   alert('월 최대 한도를 초과합니다.')
    //   return false
    // }
    // return true
  }

  const maskingAmountNumber = e => {
    e.target.value = e.target.value.replace(/[e+-]/gi, '')
    if (e.target.value < 1000 && e.target.value > 0) {
      e.target.value = e.target.value * 1000
      if (e.keyCode === 40 || e.keyCode === 46 || e.keyCode === 8) {
        e.target.value = ''
      }
    }

    if (e.target.value > 1000) {
      if (e.keyCode > 48 && e.keyCode < 58) {
        e.target.value = (e.target.value.slice(0, -4) + e.key) * 1000
      }
      if (e.keyCode > 95 && e.keyCode < 106) {
        e.target.value = (e.target.value.slice(0, -4) + e.key) * 1000
      }

      if (e.keyCode === 38) {
        e.target.value = String(
          (Number(e.target.value.slice(0, -3)) + 1) * 1000
        )
      } else if (e.keyCode === 40) {
        e.target.value = String(
          (Number(e.target.value.slice(0, -3)) - 1) * 1000
        )
      }
    }

    if (e.target.value > 300000) {
      e.target.value = 300000
    }
    state.amount = e.target.value
  }

  const controlAmountWithNumber = num => {
    state.amount = String(Number(state.amount) + num)
    if (state.amount > 300000) {
      state.amount = 300000
    }
    if (state.amount < 0) {
      state.amount = ''
    }
  }

  const fnPay = async () => {
    const params = {
      productType: proxy.$ConstCode.PRODUCT_TYPE.CHARGE.value,
      cash: state.amount,
      mobile: proxy.$Util.isMobile()
    }
    const res = await proxy.$CashSvc.postCashSettlePayLoad(params)
    if (res.resultCode !== '0000') {
      return alert(res.resultMsg)
    }
    proxy.$_.merge(state.settleForm, res)
  }
  const fnCallback = query => {
    const { POid, isSuccess } = query
    if (isSuccess < 1) return dispatch('layout/removeAllModalAlertComponent')
    if (!proxy.$Util.isEmpty(POid)) {
      router.push({
        name: 'my-page-cash-charge-done-index',
        query: {
          poid: POid
        }
      })
    } else {
      setTimeout(() => {
        alert('결제 실패하였습니다.')
      }, 500)
    }
  }

  const fnCallbackNice = async params => {
    if (!proxy.$Util.isEmpty(params)) {
      if (
        params.callType === proxy.$ConstCode.NICE_CALL_TYPE.INIT_SNS_LOGIN.value
      ) {
        fnUpdateAuthType()
      }
    } else {
      alert('본인인증에 실패하였습니다.')
    }
  }

  const fnGoBack = () => {
    let preR
    if (state.previousRoutes.length > 3) {
      preR = state.previousRoutes.splice(-3)
    } else {
      preR = [...state.previousRoutes]
    }

    if (!proxy.$Util.isEmpty(preR)) {
      if (!proxy.$Util.isEmpty(route.query)) {
        return router.push({
          name: route.query.from,
          query: { ...route.query }
        })
      } else {
        return router.push({ name: 'my-page-adjustment-index' })
      }
    } else {
      return router.push({ name: 'my-page-adjustment-index' })
    }
  }

  const fnModalAlertChargeRequestView = async () => {
    if (!isValidRequest()) return
    if (proxy.$Util.isEmpty(state.userData.authType)) return
    if (
      !proxy.$Util.isEmpty(state.userData.authType) &&
      Number(state.userData.authType) < 2
    ) {
      alert('휴대폰 인증을 완료해 주세요.')
      await fnMyInfo()
      return false
    }
    const payload = {
      component: proxy.$modalAlertNames.CHARGE_REQUEST_VIEW,
      data: {
        payType: proxy.$ConstCode.PAY_TYPE[1].name,
        amount: state.amount,
        fee: state.computedFee,
        total: state.total
      },
      callback: async () => {
        await fnPay()
      }
    }
    fnModalAlert(payload)
  }

  const fnCashInfo = async () => {
    const res = await proxy.$CashSvc.postCashChargeInfo()
    if (res.resultCode === '0000') {
      state.fee = res.fee
      state.cash = res.cash
      state.monthCharge = res.monthCharge
    } else {
      alert(res.resultMsg)
    }
  }

  const init = async () => {
    await fnCashInfo()
  }
  init()
  return {
    ...toRefs(state),
    fnCallback,
    fnPay,
    maskingAmountNumber,
    fnCallbackNice,
    isValidRequest,
    fnModalAlertChargeRequestView,
    fnGoBack,
    controlAmountWithNumber
  }
}
